import React, { useMemo } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import { WAC_URL } from '@atlassian/jira-external-urls/src/constants.tsx';
import { getAdminBaseUrl } from '@atlassian/jira-external-urls/src/utils/get-admin-base-url/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { isEligibleForJsmJpdTrialAwarenessV2 } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal/src/common/utils.tsx';
import {
	getTrialDaysLeft,
	isInGracePeriod,
	useLoggedInPage,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils';
import { FireUiAnalytics, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { EATopNavAnimatedButtonContainer } from '../../../common/ui/styled/styled.tsx';
import type { EACommonAnalyticsAttributes } from '../types.tsx';
import { BillingDetailsPopup } from './billing-details-popup/main.tsx';
import type { TriggerProps } from './billing-details-popup/types.tsx';
import { productAbbreviations } from './constants';
import { Dropdown } from './dropdown';
import {
	getJiraFeaturesDropdownSupportedEdition,
	getDemonstratingTheValueOfJiraFeaturesCohort,
	PREMIUM_FEATURES_COHORT_NOT_ENROLLED,
	PREMIUM_FEATURES_COHORT_VARIANT,
} from './dropdown/utils.tsx';
import messages from './messages';
import { TriggerButton } from './trigger-button';
import type { TrialPillButtonProps } from './types';

export const TrialPillButton = ({
	productKey,
	projectType,
	edition,
	entitlementDetails,
	invoiceGroupId,
	transactionAccountId,
	isBillingAdmin,
}: TrialPillButtonProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const { trialEndTime, billingSourceSystem } = entitlementDetails;

	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndTime);
	const inGracePeriod = isInGracePeriod(currentDate, trialEndTime);

	const isEnglishLocale = locale.startsWith('en');
	const isJswStandard = productKey === 'jira-software' && edition === STANDARD_EDITION;

	const shouldShowJsmJpdTrialAwarenessModalV2 = isEligibleForJsmJpdTrialAwarenessV2(projectType);

	const { cohort: premiumFeaturesCohort } = getDemonstratingTheValueOfJiraFeaturesCohort({
		shouldFire: false,
		productKey,
		edition,
		inGracePeriod,
	});

	// I wanted to add a comment to this condition, but comment would be longer then the component itself so I gave up ...
	const jstoTarget = !!isBillingAdmin && !inGracePeriod && isSiteAdmin && isJswStandard;

	const rawJstoUrl = `${
		WAC_URL[environment]
	}/software/jira?ref=edition_awareness_standard_trial_${billingSourceSystem.toLowerCase()}&cloudSiteId=${cloudId}`;
	const jstoUrl = useLoggedInPage({ pageUrl: rawJstoUrl });

	const addBillingDetailsUrl =
		entitlementDetails?.billingSourceSystem === 'CCP'
			? `${getAdminBaseUrl()}/billing/${transactionAccountId}/${invoiceGroupId}/payment-flow?referrer=${productAbbreviations[productKey]}`
			: `${getAdminBaseUrl()}/s/${cloudId}/billing/paymentdetails/add`;

	const tooltipMessage = formatMessage(
		inGracePeriod ? messages.addPaymentDetailsTooltip : messages.trialButtonTooltip,
	);

	const analyticsAttributes: EACommonAnalyticsAttributes = useMemo(() => {
		const attributes: EACommonAnalyticsAttributes = {
			edition,
			productKey,
			projectType,
			isEnglishLocale,
			entitlementDetails,
			transactionAccountId,
			invoiceGroupId,
			isBillingAdmin,
		};

		// We don't want to send this attribute if user is not enrolled in the experiment
		if (premiumFeaturesCohort !== PREMIUM_FEATURES_COHORT_NOT_ENROLLED) {
			attributes.premiumFeaturesCohort = premiumFeaturesCohort;
		}

		return attributes;
	}, [
		edition,
		entitlementDetails,
		invoiceGroupId,
		isEnglishLocale,
		productKey,
		projectType,
		transactionAccountId,
		isBillingAdmin,
		premiumFeaturesCohort,
	]);

	const triggerButtonProps = useMemo(
		() => ({
			trialDaysLeft,
			inGracePeriod,
			jstoUrl: jstoTarget ? jstoUrl : undefined,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
			shouldShowJsmJpdTrialAwarenessModalV2,
		}),
		[
			trialDaysLeft,
			inGracePeriod,
			jstoTarget,
			jstoUrl,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
			shouldShowJsmJpdTrialAwarenessModalV2,
		],
	);

	let content: null | JSX.Element = null;

	if (jstoTarget || inGracePeriod) {
		content = (
			<EATopNavAnimatedButtonContainer>
				<Tooltip content={tooltipMessage} position="bottom">
					{(tooltipProps) => <TriggerButton {...triggerButtonProps} triggerProps={tooltipProps} />}
				</Tooltip>
			</EATopNavAnimatedButtonContainer>
		);
	} else if (shouldShowJsmJpdTrialAwarenessModalV2) {
		content = (
			<EATopNavAnimatedButtonContainer>
				<TriggerButton {...triggerButtonProps} />
			</EATopNavAnimatedButtonContainer>
		);
	} else {
		content = (
			<EATopNavAnimatedButtonContainer>
				<BillingDetailsPopup
					analyticsAttributes={analyticsAttributes}
					edition={edition}
					inGracePeriod={inGracePeriod}
					addBillingDetailsUrl={addBillingDetailsUrl}
					productKey={productKey}
				>
					{(triggerProps: TriggerProps) => (
						<TriggerButton {...triggerButtonProps} {...triggerProps} />
					)}
				</BillingDetailsPopup>
			</EATopNavAnimatedButtonContainer>
		);
	}

	if (premiumFeaturesCohort === PREMIUM_FEATURES_COHORT_VARIANT) {
		content = (
			<Dropdown
				applicationEdition={getJiraFeaturesDropdownSupportedEdition(edition, productKey)}
				productKey={productKey}
				status="default"
				analyticsAttributes={analyticsAttributes}
				trialEndTime={trialEndTime}
			>
				<FormattedMessage
					id="navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.days-left-button"
					defaultMessage="{trialDaysLeft, plural, one {# day} other {# days}} left"
					description="Trial information button title showing how many days is left in trial"
					values={{
						trialDaysLeft,
					}}
				/>
			</Dropdown>
		);
	}

	if (content !== null) {
		return (
			<ContextualAnalyticsData attributes={{ isSiteAdmin, isBillingAdmin }}>
				<SpotlightTarget name="edition-awareness-trial-pill">
					{content}
					<FireUiAnalytics
						action="viewed"
						actionSubject="button"
						actionSubjectId="editionAwarenessTrialPill"
						attributes={analyticsAttributes}
					/>
				</SpotlightTarget>
			</ContextualAnalyticsData>
		);
	}
	return null;
};
